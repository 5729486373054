@import '../app/_shared/components/ui-help/loader/loader.component';
@import '../app/_shared/components/ui-help/dots-loader/dots-loader.component';

#loader > div {
  display: none;
}

#loader-auth {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #dce2ff 0%, #e4d5ff 33%, #fadcff 66%, #fddfeb 100%);
  background-color: #dce2ff; // fallback

  @media screen and (max-width: 540px) {
    background: #fff;
  }

  .auth-header {
    display: flex;
    flex-direction: column;
    min-height: 60px;
  }

  .auth-content {
    z-index: 1;
    position: relative;
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;

    .auth-form {
      max-width: 500px;
      width: 100%;
      flex: 2;
      text-align: center;
      position: relative;
      min-height: 625px;
      margin: 0 auto 100px;

      .auth-box {
        width: 760px;
        height: 600px;
        border-radius: 50px;
        @include shadow(s);
        background-color: white;
        padding: 75px 210px;
        position: absolute;
        top: 50%;
        left: 50%;
        box-sizing: border-box;
        transform: translateX(-50%) translateY(-50%);
        overflow: hidden;

        @media screen and (max-width: 540px) {
          box-shadow: none;
          top: 0;
          transform: translateX(-50%);
        }

        .logo {
          margin-bottom: 30px;
          display: inline-block;
        }

        .dots-loader {
          margin-top: 140px;
          justify-content: center;

          &__dot {
            background-color: $gray-light-200;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(s) {
    .auth-content {
      .auth-form {
        .auth-box {
          max-width: 95%;
          padding: 75px 25px;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    min-height: 550px;

    .auth-content {
      .auth-form {
        .auth-box {
          max-width: 95%;
          height: 500px;
          padding: 40px 15px;
          box-shadow: none;
        }
      }
    }
  }
}

#loader-framework {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 9999;
  background-color: $color-white;

  &.no-menu {
    .framework-wrapper {
      .menu {
        display: none;
      }
    }
  }

  .framework-wrapper {
    display: flex;

    .menu {
      background-color: $color-smoke;
      width: 280px;
      height: 100vh;
      border-right: 1px solid var(--color-fog);
      box-sizing: border-box;

      .logo-wrapper {
        height: 32px;
      }

      .upper-menu {
        gap: $spacing-xxl;
      }
    }

    .header {
      background-color: var(--color-white);
      height: 64px;
      box-sizing: border-box;
      width: 100%;

      .right-menu {
        gap: $spacing-s;
      }
    }

    .footer {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: var(--color-white);
      flex: 1;
      height: 61px;
      width: 100%;
      border-top: 1px solid var(--color-fog);
      padding: 10px 10px;
      box-sizing: border-box;
    }
  }

  @media (max-width: 740px) {
    .framework-wrapper {
      .header {
        .right-menu {
          display: none;
        }
      }
    }
  }

  @media (max-width: 543px) {
    .framework-wrapper {
      .menu {
        display: none;
      }

      .footer {
        display: flex;
      }
    }
  }
}
