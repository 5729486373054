@use './mixins' as mixins;
@use './variables' as variables;

@mixin txt-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin font-size-l() {
  @include mixins.font-size(xl);
  font-weight: variables.$font-weight-normal;
}

@mixin font-size-m() {
  @include mixins.font-size(m);
  font-weight: variables.$font-weight-normal;
}

@mixin font-size-s() {
  @include mixins.font-size(s);
  font-weight: variables.$font-weight-normal;
}

@mixin font-size-xs() {
  @include mixins.font-size(xs);
  font-weight: variables.$font-weight-normal;
}

@mixin heading() {
  margin: 0;
  color: var(--color-text-normal);
}

@mixin heading-h1() {
  @include mixins.font-size(xxl);
  font-weight: variables.$font-weight-bold;
}

@mixin heading-h2() {
  @include mixins.font-size(xl);
  font-weight: variables.$font-weight-bold;
}

@mixin heading-h3() {
  @include mixins.font-size(l);
  font-weight: variables.$font-weight-bold;
}

@mixin heading-h4() {
  @include mixins.font-size(m);
  font-weight: variables.$font-weight-bold;
}

/* EMPTY MESSAGES */
@mixin txt-empty() {
  text-align: center;
  @include mixins.font-size(s);
  color: var(--color-steel);
  font-style: italic;
}

/* LINKS */
@mixin txt-link() {
  color: var(--color-text-blue);
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

@mixin txt-link-purple() {
  color: var(--color-text-purple);
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}
