@import 'libs/ease/ease-import';

.dots-loader {
  display: flex;
  &--s {
    gap: 2px;

    span {
      width: 4px;
      height: 4px;
    }
  }

  &--m {
    gap: 4px;

    span {
      width: 8px;
      height: 8px;

      animation-name: button-loader-m;
    }
  }

  &--l {
    gap: 8px;

    span {
      width: 24px;
      height: 24px;

      animation-name: button-loader-l;
    }
  }

  &__dot {
    border-radius: 50%;
    animation: button-loader 1.6s linear infinite;

    vertical-align: text-bottom;
    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @each $name, $color in $border-colors {
    &--#{$name} {
      background: $color;
    }
  }
  &--always-white {
    background: #fff;
  }
}

@keyframes button-loader {
  0%,
  40%,
  100% {
    transform: initial;
  }
  20% {
    transform: translateY(-8px);
  }
}

@keyframes button-loader-m {
  0%,
  40%,
  100% {
    transform: initial;
  }
  20% {
    transform: translateY(-12px);
  }
}

@keyframes button-loader-l {
  0%,
  40%,
  100% {
    transform: initial;
  }
  20% {
    transform: translateY(-24px);
  }
}
