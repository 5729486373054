@import 'libs/ease/ease-import';

// Shared styles for html created by the rich-text directive
@mixin rich-text-html {
  font-weight: 400;

  p {
    margin-bottom: $spacing-s;
    font-weight: 400; // we use 400 so the editor can handle `bold` properly
  }

  h2 {
    margin: 0 0 1em 0;
    font-weight: $font-weight-bold;
  }

  i {
    font-style: italic;
  }

  // otherwise, the preview icon of attached images is being distored
  i.aw-2-icn {
    font-style: normal;
  }

  & > ul {
    margin: $spacing-s;
    padding-left: $spacing-s;

    ul {
      margin: $spacing-xs 0 $spacing-xs $spacing-l;
    }
  }

  & > ol {
    margin: $spacing-s;
    padding-left: $spacing-s;

    ol {
      margin: $spacing-xs 0 $spacing-xs $spacing-l;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    font-weight: 400;

    li {
      margin-bottom: $spacing-xs;
    }
  }

  hr {
    margin: $spacing-xxxl 0;
    border: 0;
    border-top: 1px solid $color-fog;
  }
}

@mixin rich-text-code-element {
  code {
    background: $color-sky;
    color: $color-slate;
    padding: $spacing-xs;
    border-radius: 4px;
    font-family: monospace;
    font-weight: $font-weight-normal;
    font-size: $font-size-small;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }

  h1 {
    code {
      font-size: $font-size-h1;
    }
  }

  h2 {
    code {
      font-size: 20px;
    }
  }

  h3 {
    code {
      font-size: $font-size-h3;
    }
  }
}
