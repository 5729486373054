@import './_mixins.scss';

:root {
  @each $name, $color in $colors {
    @each $shade, $value in $color {
      --color-#{$name}-#{$shade}: #{$value};
    }
  }
}

/*
 * CSS VARIABLES FOR AUTO DARK/LIGHT MODE
 */
:root {
  --color-white: #{$gray-light-0};
  --color-smoke: #{$gray-light-25};
  --color-ice: #{$gray-light-50};
  --color-sky: #{$gray-light-100};
  --color-fog: #{$gray-light-200};
  --color-dust: #{$gray-light-300};
  --color-light-steel: #{$gray-light-400};
  --color-steel: #{$gray-light-500};
  --color-slate: #{$gray-light-600};
  --color-midnight: #{$gray-light-700};
  --color-asphalt: #{$gray-light-800};
  --color-night: #{$gray-light-900};

  --color-white-rgb: 255, 255, 255;
  --color-ice-rgb: 242, 249, 255;
  --color-sky-rgb: 235, 245, 255;
  --color-fog-rgb: 219, 237, 255;
  --color-dust-rgb: 200, 220, 244;
  --color-steel-rgb: 85, 118, 170;

  --color-shadow-rgb: 15, 40, 77;

  --color-icon: var(--color-steel);
  --color-border: var(--color-fog);
  --color-fat-border: var(--color-sky);
  --color-contrasty-border: var(--color-dust);
  --color-input: var(--color-white);

  --color-text-normal: var(--color-night);
  --color-text-light: var(--color-steel);

  --shadow-opacity-1: 0.01;
  --shadow-opacity-2: 0.02;
  --shadow-opacity-3: 0.03;
  --shadow-opacity-4: 0.04;
  --shadow-opacity-5: 0.05;

  --overlying-bg: #{$gray-light-0};
  --overlying-bg-rgb: 255, 255, 255;

  --dark-element-bg: #{$gray-light-900};
  --dark-element-text: #{$gray-light-0};

  --color-text-red: #{$color-red-900};
  --color-text-green: #{$color-green-900};
  --color-text-blue: #{$color-blue-900};
  --color-text-yellow: #{$color-yellow-900};
  --color-text-purple: #{$color-purple-900};
  --color-text-orange: #{$color-orange-900};
  --color-text-cyan: #{$color-cyan-900};
  --color-text-indigo: #{$color-indigo-900};
  --color-text-teal: #{$color-teal-900};

  --color-red: #{$color-red};
  --color-green: #{$color-green};
  --color-blue: #{$color-blue};
  --color-yellow: #{$color-yellow};
  --color-purple: #{$color-purple};
  --color-orange: #{$color-orange};
  --color-cyan: #{$color-cyan};
  --color-indigo: #{$color-indigo};
  --color-teal: #{$color-teal};

  --color-red-300: #{$color-red-300};
  --color-green-300: #{$color-green-300};
  --color-blue-300: #{$color-blue-300};
  --color-yellow-300: #{$color-yellow-300};
  --color-purple-300: #{$color-purple-300};
  --color-orange-300: #{$color-orange-300};
  --color-cyan-300: #{$color-cyan-300};
  --color-indigo-300: #{$color-indigo-300};

  --color-red-50: #{$color-red-50};
  --color-green-50: #{$color-green-50};
  --color-blue-50: #{$color-blue-50};
  --color-yellow-50: #{$color-yellow-50};
  --color-purple-50: #{$color-purple-50};
  --color-orange-50: #{$color-orange-50};
  --color-cyan-50: #{$color-cyan-50};
  --color-indigo-50: #{$color-indigo-50};
}

// DARK MODE
body.aw-2-dark {
  --color-white: #{$gray-dark-0};
  --color-smoke: #{$gray-dark-25};
  --color-ice: #{$gray-dark-50};
  --color-sky: #{$gray-dark-100};
  --color-fog: #{$gray-dark-200};
  --color-dust: #{$gray-dark-300};
  --color-light-steel: #{$gray-dark-400};
  --color-steel: #{$gray-dark-500};
  --color-slate: #{$gray-dark-600};
  --color-midnight: #{$gray-dark-700};
  --color-asphalt: #{$gray-dark-800};
  --color-night: #{$gray-dark-900};

  --color-white-rgb: 26, 28, 40;
  --color-ice-rgb: 30, 32, 45;
  --color-sky-rgb: 40, 42, 57;
  --color-fog-rgb: 53, 56, 74;
  --color-dust-rgb: 76, 79, 100;
  --color-steel-rgb: 144, 151, 177;

  --color-shadow-rgb: 0, 0, 0;

  --color-icon: var(--color-steel);
  --color-border: var(--color-fog);
  --color-fat-border: var(--color-sky);
  --color-contrasty-border: var(--color-dust);
  --color-input: var(--color-white);

  --color-text-normal: var(--color-night);
  --color-text-light: var(--color-steel);

  --shadow-opacity-1: 0.1;
  --shadow-opacity-2: 0.1;
  --shadow-opacity-3: 0.1;
  --shadow-opacity-4: 0.1;
  --shadow-opacity-5: 0.1;

  --overlying-bg: #{$gray-dark-100};
  --overlying-bg-rgb: 40, 42, 57;

  --dark-element-bg: #{$gray-dark-100};
  --dark-element-text: #{$gray-dark-900};

  --color-text-red: #{$color-red-700};
  --color-text-green: #{$color-green-700};
  --color-text-blue: #{$color-blue-700};
  --color-text-yellow: #{$color-yellow-700};
  --color-text-purple: #{$color-purple-700};
  --color-text-orange: #{$color-orange-700};
  --color-text-cyan: #{$color-cyan-700};
  --color-text-indigo: #{$color-indigo-700};
  --color-text-teal: #{$color-teal-700};
}

// Gradients
.aw-2-bg--purple-gradient {
  background: $gradient-purple;
  color: $color-night;
}

// Backgrounds
@each $name, $color in $bg-colors {
  .aw-2-bg--#{$name} {
    background-color: $color;
  }
}

:root {
  @each $name, $color in $bg-colors {
    --color-bg-#{$name}: #{$color};
  }
}

// Borders
@each $name, $color in $border-colors {
  .aw-2-b--#{$name} {
    border: 1px solid $color !important;
  }
}
